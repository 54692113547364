.fs-12 {
  font-size: 12px;
  line-height: 18px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-24 {
  font-size: 1.334rem;
  line-height: 2.223rem;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.text-orange {
  color: $orange;
}
