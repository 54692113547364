a.btn {
  line-height: 2.4;
}

.btn {
  min-height: 48px;

  &.btn-gray {
    color: #4E5C65;
  }

  &.btn-primary {
    color: #FAFAFA;
  }
}
