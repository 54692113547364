// Typography
$font-size-root: 15px;
$font-family-sans-serif: 'Poppins', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Headings
$h1-font-size: 1.34rem;
$headings-font-weight: 600;
$headings-color: #283F63;

// Theme Colors
$body-bg: transparent;
$body-color: #283F63;

$primary: #2676E6;
$dark: #283F63;
$orange: #FAA02B;

$theme-colors: (
  'primary': $primary,
  'dark': $dark
);

// Inputs
$input-font-size: 0.87rem;
$input-border-radius: 50px;
$input-line-height: 2.1;
$input-border-color: rgba(11, 28, 70, 0.23);
$input-color: #0B1C46;

// Input type checkbox
$form-check-input-width: 1.47em;
$form-check-input-border-radius: 0;

// Labels
$form-label-font-size: 0.93rem;
$form-label-font-weight: 400;
$form-label-color: #54607E;

// Buttons
$btn-font-weight: 500;
$btn-font-size: 0.94rem;
$btn-border-radius: 25px;
$btn-padding-x: 30px;

// Hr
$hr-height: 2px;
$hr-opacity: 1;

// Spaces
$spacer: 1.34rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: 0.8rem,
  3: $spacer,
  4: $spacer * 1.5,
  5: 3.53rem,
) !default;

// scss-docs-start import-stack
// Configuration
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
// @import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
// @import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/transitions";
// @import "../../../node_modules/bootstrap/scss/dropdown";
// @import "../../../node_modules/bootstrap/scss/button-group";
// @import "../../../node_modules/bootstrap/scss/nav";
// @import "../../../node_modules/bootstrap/scss/navbar";
// @import "../../../node_modules/bootstrap/scss/card";
// @import "../../../node_modules/bootstrap/scss/accordion";
// @import "../../../node_modules/bootstrap/scss/breadcrumb";
// @import "../../../node_modules/bootstrap/scss/pagination";
// @import "../../../node_modules/bootstrap/scss/badge";
// @import "../../../node_modules/bootstrap/scss/alert";
// @import "../../../node_modules/bootstrap/scss/progress";
// @import "../../../node_modules/bootstrap/scss/list-group";
// @import "../../../node_modules/bootstrap/scss/close";
// @import "../../../node_modules/bootstrap/scss/toasts";
// @import "../../../node_modules/bootstrap/scss/modal";
// @import "../../../node_modules/bootstrap/scss/tooltip";
// @import "../../../node_modules/bootstrap/scss/popover";
// @import "../../../node_modules/bootstrap/scss/carousel";
// @import "../../../node_modules/bootstrap/scss/spinners";
// @import "../../../node_modules/bootstrap/scss/offcanvas";
// @import "../../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack
